<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registro de movimientos - {{ this.document.sku }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <div v-if="progress" class="mt-4 mb-4" style="width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
        <div>
          <vs-button @click="exportToExcel" color="dark" type="border" class="w-full mb-8">Descargar</vs-button>
        </div>
        <!--List-->
        <div class="mt-8">
          <vs-table class="mt-5" :data="movements">
            <template slot="thead">
              <vs-th>Nro Doc.</vs-th>
              <vs-th>Tipo</vs-th>
              <vs-th>Fecha</vs-th>
              <vs-th>Hora</vs-th>
              <vs-th>Motivo</vs-th>
              <vs-th>Almacén</vs-th>
              <vs-th>Stock previo</vs-th>
              <vs-th>Cantidad</vs-th>
              <vs-th>Precio</vs-th>
              <vs-th>Proveedor</vs-th>
              <vs-th v-if="user === 'christian@salsa.pe'">Eliminar</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <!--Type doc-->
                <vs-td>
                  <p v-if="tr.type === 'invoice' || tr.type === 'sale' || tr.type === 'creditNote' || tr.type === 'transferIn' || tr.type === 'transferOut'">
                    {{ tr.document }}</p>
                  <p v-else>{{ tr.id.substr(0, 6).toUpperCase() }}</p>
                </vs-td>
                <!--End-->

                <vs-td>
                  <p> {{ getType(tr.type) }}</p>
                </vs-td>

                <vs-td>
                  <p> {{ tr.createdAt | date(true) }}</p>
                </vs-td>

                <vs-td>
                  <p> {{ tr.createdAt | time(true) }}</p>
                </vs-td>

                <vs-td>
                  <p>{{ tr.motive }}</p>
                </vs-td>

                <vs-td>
                  <p>{{ tr.warehouse.name }}</p>
                </vs-td>

                <!--Old stock-->
                <vs-td>
                  <p>{{ tr.oldStock }}</p>
                </vs-td>

                <!--Quantity-->
                <vs-td
                    v-if="tr.type === 'transferOut' || tr.type === 'sale' || tr.type ==='creditNoteInvoice' || tr.type === 'out'">
                  <p>{{ tr.quantity * -1 }}</p>
                </vs-td>
                <vs-td v-else>
                  <p>{{ tr.quantity }}</p>
                </vs-td>

                <vs-td v-if="tr.currency">
                  <p>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: `${tr.currency.code}`
                      }).format(tr.cost - tr.discount)
                    }}</p>
                </vs-td>
                <vs-td v-else>
                  <p>PEN 0</p>
                </vs-td>

                <vs-td>
                  <p v-if="tr.type === 'invoice'">{{ tr.provider.name }}</p>
                  <p v-else>NA</p>
                </vs-td>

                <vs-td v-if="user === 'christian@salsa.pe'">
                  <feather-icon @click="deleteMovement(tr, indextr)"
                                class="cursor-pointer" icon="TrashIcon"
                                svgClasses="h-4 w-4"/>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { auth, db } from '@/firebase/firebaseConfig'
import { FieldValue } from '../../firebase/firebaseConfig'

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {},
      movements: [],
      progress: false
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (!val) return
      this.document = {
        ...this.data
      }
      await this.getMovements(this.document)
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    user () {
      return auth.currentUser.email
    }
  },
  methods: {
    /**
     * Get type movements
     */
    getType (type) {
      if (type === 'input') {
        return 'Ingreso manual'
      } else if (type === 'out') {
        return 'Salida manual'
      } else if (type === 'transferIn') {
        return 'Transferencia - Ingreso'
      } else if (type === 'transferOut') {
        return 'Transferencia - Salida'
      } else if (type === 'invoice') {
        return 'Comprobante de compra'
      } else if (type === 'sale') {
        return 'Comprobante de venta'
      } else if (type === 'creditNoteInvoice') {
        return 'Nota de crédito prov.'
      } else if (type === 'creditNote') {
        return 'Nota de crédito'
      }
    },
    /**
     * Get movements
     */
    async getMovements (product) {
      try {
        this.progress = true
        this.movements = []
        const querySnapshot = await db.collection('products').doc(product.id).collection('movements').orderBy('createdAt', 'desc').get()
        querySnapshot.forEach((doc) => {
          console.log(doc.id)
          let obj = {
            ...doc.data()
          }
          delete obj.id
          this.movements.push({
            id: doc.id,
            ...obj,
            createdAt: doc.data().createdAt.toDate(),
            oldStock: doc.data().oldStock || 0,
            discount: doc.data().discount || 0
          })
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Delete movement
     * @param id
     * @returns {Promise<void>}
     */
    async deleteMovement (movement, index) {
      try {
        console.log(movement.id)
        this.progress = true
        await db.collection('products').doc(this.document.id).collection('stocks').doc(movement.warehouse.id).update({
          stock: FieldValue.increment(movement.quantity * -1)
        })
        await db.collection('products').doc(this.document.id).collection('movements').doc(movement.id).delete()
        this.movements.splice(index, 1)
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'Movimiento eliminado.'
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Exports to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.movements)
        list.forEach((i) => {
          i.doc = (i.type === 'invoice' || i.type === 'sale') ? i.document : i.id.substr(0, 6).toUpperCase()
          i.type = this.getType(i.type)
          i.date = this.$options.filters.date(i.createdAt, true)
          i.hour = this.$options.filters.time(i.createdAt, true)
          i.warehouse = i.warehouse.name
          i.provider = i.provider ? i.provider.name : 'NA'
        })
        const headerVal = ['doc', 'type', 'date', 'hour', 'motive', 'warehouse', 'quantity', 'provider']
        const headerTitle = ['ID', 'Tipo', 'Fecha', 'Hora', 'Motivo', 'Local', 'Cantidad', 'Proveedor']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de movimientos - ' + this.document.sku,
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 1250px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
