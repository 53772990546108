<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Eidtar producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Name -->
        <vs-input @blur="document.name= trimString(document.name)" label="Nombre" v-model="document.name"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- SKU -->
        <vs-input label="SKU" v-model="document.sku"
                  class="mt-5 w-full" name="sku"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('sku')">{{ errors.first('sku') }}</span>

        <!-- Price -->
        <vs-input type="number" label="Precio inicial de venta" v-model.number="document.price" class="mt-5 w-full"
                  name="price"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('price')">{{ errors.first('price') }}</span>

        <!-- Purchase price -->
        <vs-input type="number" label="Precio inicial de compra" v-model.number="document.purchasePrice"
                  class="mt-5 w-full"
                  name="purchasePrice"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('purchasePrice')">{{ errors.first('purchasePrice') }}</span>

        <!--Type-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Tipo de producto </label>
          <v-select
              :options="[{label: 'Regular', id: 'regular'}, {label: 'Combo', id:'combo'}]"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="document.type"
              class="mb-4 md:mb-0"
              name="type"
              v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>

        <!--Categories-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Categoría</label>
          <v-select label="name" :options="categories" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.category" class="mb-4 md:mb-0" name="category" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('category')">{{ errors.first('category') }}</span>
        </div>

        <!-- Brands -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Marca</label>
          <v-select label="name" :options="brands" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.brand" class="mb-4 md:mb-0" name="brand" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
        </div>

        <!--Related products-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Productos relacionados</label>
          <v-select
              :options="compactList"
              :clearable="true"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="document.relatedProducts"
              multiple
              class="mb-4 md:mb-0"
              name="relatedProducts"/>
        </div>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    categories: {
      type: Array,
      required: true
    },
    brands: {
      type: Array,
      required: true
    },
    compactList: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      const result = await this.$validator.validateAll()
      const obj = {
        ...this.document
      }
      obj.sku = obj.sku.toUpperCase()
      if (result) {
        this.progress = true
        await db.collection('products').doc(this.document.id).update({
          ...obj,
          name: obj.name,
          uid: auth.currentUser.uid,
          updatedAt: FieldValue.serverTimestamp()
        })
        this.$emit('update', obj)
        this.$emit('closeSidebar')
        this.progress = false
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'Producto modificado correctamente.'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
